import React from "react"
import PrivacyPage from "../components/pages/privacy"
import SEO from "../components/seo"

const PoliticaPrivacidadePage = () => (
  <>
    <SEO title="Política de Privacidade" />
    <PrivacyPage />
  </>
)

export default PoliticaPrivacidadePage
