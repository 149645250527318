import styled from '@emotion/styled';

export default styled.h2`
  font-family: Bitter;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f5f5f;
  margin: 20px 0;
`;
